/**
 * 作業用の `div` 要素を作成します。
 *
 * @returns 作業用 `div` 要素
 */
export function createWorkingArea(): HTMLDivElement {
    const element = document.createElement("div");

    element.style.position = "fixed";
    element.style.left = "0";
    element.style.top = "0";
    element.style.width = "100%";
    element.style.height = "100%";
    element.style.contain = "strict";
    element.style.visibility = "hidden";
    element.style.pointerEvents = "none";
    document.body.appendChild(element);

    return element;
}
